import { Translations } from '@aws-amplify/ui-components';
import {
  Authenticator,
  CheckboxField,
  Divider,
  Flex,
  useTheme,
  View,
} from '@aws-amplify/ui-react';
import { I18n } from 'aws-amplify';
import { FunctionComponent, ReactNode, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import Auth from '@/components/auth';
import Heading from '@/components/heading';
import Hidden from '@/components/hidden';
import Name from '@/components/name';
import TimeZone from '@/components/time-zone';

interface SignUpAttributes {
  userId: string;
}

const USER_ID_KEY = 'custom:user_id';

I18n.setLanguage('en-AU');
I18n.putVocabulariesForLanguage('en-AU', {
  [Translations.CHANGE_PASSWORD]: 'Submit',
});

const ForcePassword: FunctionComponent<{
  fields: ReactNode;
}> = ({ fields }) => {
  const { tokens } = useTheme();
  return (
    <>
      <Heading>Set your password</Heading>
      <Authenticator.ForceNewPassword.FormFields />
      <Divider
        marginTop={tokens.space.medium}
        marginBottom={tokens.space.small}
      />
      {fields}
    </>
  );
};

const UserPage: FunctionComponent = () => {
  const { tokens } = useTheme();
  const [signUp, setSignUp] = useState<SignUpAttributes>({
    userId: uuidv4(),
  });

  // Define the fields needed to capture user attributes
  const now = new Date();
  const utc = now.toISOString();

  const fields = (
    <View>
      <View marginBottom={tokens.space.xxs}>
        <Heading>Tell us about yourself</Heading>
      </View>
      <View marginBottom={tokens.space.medium}>
        <Hidden name={USER_ID_KEY} value={signUp.userId} />
        <TimeZone />
        <Name />
        <Divider marginTop={tokens.space.xl} marginBottom={tokens.space.xl} />
        <Flex alignItems="flex-start" justifyContent="center">
          <CheckboxField
            label=""
            name="custom:agree_legal"
            value={utc}
            required={true}
            marginTop={tokens.space.xxxs}
          />
          <View>
            I agree to{' '}
            <a
              className="legal__link"
              href="https://knowledgebase.honeag.com/terms"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Service and Privacy Policy
            </a>
          </View>
        </Flex>
      </View>
    </View>
  );

  return (
    <>
      <Auth
        logo={{
          color: 'var(--amplify-colors-brand-primary-90)',
          label: 'Hone',
        }}
        forcePassword={<ForcePassword fields={fields} />}
        onSignIn={(response) => {
          // For admin created accounts a new password is forced
          // A user id is also set, which needs to be maintained across the sign up
          if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
            const {
              challengeParam: { userAttributes },
            } = response;

            // Apply any existing sign up attributes
            const userId = userAttributes[USER_ID_KEY];

            if (userId) {
              setSignUp({
                userId,
              });
            }
          }
        }}
        onCreateClaims={(idToken, claims) => ({
          ...claims,
          userId: idToken[USER_ID_KEY],
        })}
      />
    </>
  );
};

export default UserPage;
